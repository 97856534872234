import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src74448501/src/kinetic-ui/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "ismemberof"
    }}>{`isMemberOf`}</h1>
    <p>{`Returns true or false if a user (or profile) is a member of the named team.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { Utils } from '@kineticdata/bundle-common';

Utils.isMemberOf(user, 'IT Dept'); // returns true if the user is a member.
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      